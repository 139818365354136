import CONFIG from "./../speedshop.config.js"
import template from '@speedshop/template'
import '@speedshop/template/src/common/css/var.css'
import '@speedshop/template/dist/Electronics/index.cjs.css'
import '@/assets/css/var.css'
import Vue from 'vue'
import 'element-ui/lib/theme-chalk/index.css'
import ApiSdk, { createSDK } from '@speedshop/sdk-api'
import { Message, Loading, Popover } from 'element-ui'
import Cookies from 'js-cookie'
import VueI18n from 'vue-i18n'
import eleLocale from 'element-ui/lib/locale'
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import viLocale from 'element-ui/lib/locale/lang/vi'
import idLocale from 'element-ui/lib/locale/lang/id'
import vnLocal from '@speedshop/template/src/common/locale/lang/vi.ts'
import enLocal from '@speedshop/template/src/common/locale/lang/en.ts'
import zhLocal from '@speedshop/template/src/common/locale/lang/zh.ts'
import idLocal from '@speedshop/template/src/common/locale/lang/id.ts'
import message from "@/i18n/index.ts"
import { MergeRecursive } from '@/utils/tools.ts'

import 'animate.css/animate.min.css'

Vue.use(Loading)
Vue.use(Popover)
const FORMAT_MAP_DATA=[{prop:"$",label:'price'},{prop:"$Address",label:"address"},{prop:"$D",label:"date"}]
FORMAT_MAP_DATA.forEach(item => {
  Vue.filter(item.prop, CONFIG.format[item.label])
})

const { APIURL,graphql={}} = CONFIG
const { errorHandler } = graphql

const defaultOptions = ({ isServer, uri, app, redirect }) => {
  const whiteCodeListWithNoErrorMessage = ["mfa.validate.code.null"]
  return {
    uri,
    async errorHandler({ message, code, ...others }) {
      if (errorHandler) {
        errorHandler({ message, code, ...others,app,isServer,redirect })
        return
      }
      if (code === '0002') {
        const {
          currentRoute: { fullPath, name }
        } = app.router

        const path = '/login?redirectUrl=' + encodeURIComponent(fullPath)

        if (isServer) {
          app.$cookies.set('token', '')
        } else {
          Cookies.remove('token')
          localStorage.removeItem('token')
          localStorage.removeItem('userInfo')
          sessionStorage.clear()
        }

        // 没有登录时候如果当前不在登录页面
        if (!['login'].includes(name)) {
          if (isServer) {
            redirect(path)
            return false
          } else {
            window.location.href = window.location.origin + path
            return false
          }
        } else {
          return Promise.reject(message)
        }
      } else if (isServer) {
        return Promise.reject(message)
      }  else if (!whiteCodeListWithNoErrorMessage.includes(code)) {
        Message.error(message)
      }
    },
    setContext: set => {
      const cookies = app.$cookies.getAll();
      const token = isServer
        ?cookies.token
        : localStorage.getItem('token')
      const lang = isServer
        ? app.$cookies.get('gt-local') || app.store.state.locale
        : localStorage.getItem('gt-local');
      let siteUrl=process.env.__GTECH_VARIABLES_SITE_URL || CONFIG.SITEURL[process.env.PRO_ENV]
      set({
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Origin-Alias':
            process.env.NODE_ENV === 'production'
              ? app.store.state.OriginALias
              : siteUrl,
          'AUTH-TOKEN': token || '',
          Language: lang,
         // 'ORG-CODE': CONFIG.orgCode,
          'SITE-CODE': CONFIG.siteCode,
        }
      })
    }
  }
}

export default function ({ app, store, redirect }) {
  Vue.use(VueI18n)
  const {en,vn,zh,id,...others}=message
  // MergeRecursive 深层次合并对象
  const messages = {
    ...others,
    'en-US': { ...enLocale, ...MergeRecursive(enLocal, en) },
    'vi-VN': { ...viLocale, ...MergeRecursive(vnLocal, vn) },
    'zh-CN': { ...zhLocale, ...MergeRecursive(zhLocal, zh) },
    'id-ID': { ...idLocale, ...MergeRecursive(idLocal, id) }
  }

  const i18n = new VueI18n({
    locale: store.state.locale || 'en-US',
    fallbackLocale: process.env.lang || 'en-US',
    messages
  })

  app.i18n = i18n

  let url = process.env.__GTECH_VARIABLES_GRAPHQL_URL__ || APIURL[process.env.PRO_ENV];
  const config = defaultOptions({
    isServer: process.server,
    uri: process.server ? url : store.state.APIURI,
    app,
    redirect
  })
  Vue.use(ApiSdk)
  const sdk = createSDK({
    ...config,
    //isMobile: app.$store.getters.isMobile
  })

  app.sdk = sdk
  if (process.server) {
    store.commit('setAPIURI', url)
  } else {
    store.dispatch('nuxtClientInit')
  }

  eleLocale.i18n((key, value) => i18n.t(key, value))

  Vue.use(template, {
    local: enLocal,
    app,
    graphqlConfig: config,
    sdk,
    store,
    isServer: process.server,
    i18n: (key, value) => app.i18n.t(key, value)
  })
}
