import Vue from 'vue'
import Vuex, { Store } from 'vuex'
import { isMobile } from '@speedshop/template'
import debounce from 'lodash/debounce'
import CONFIG from "./../speedshop.config.js"
const mobileScreenSize=CONFIG.mobileScreenSize||756
const {store:{
  state={},
  actions:{
    nuxtServerInit,
    nuxtClientInit,
    ...actions
  },
  getters={},
  mutations={}
}}=CONFIG
const langMap = { en: 'en-US' }
const initPlatform = commit => {
  const setPlatform = () => {
    const body =
      document.compatMode !== 'BackCompat'
        ? document.documentElement
        : document.body
    const clientWidth = body.clientWidth
    return clientWidth < mobileScreenSize ? 'H5' : 'PC'
  }
  const savePlatform = () => {
    const result = setPlatform()
    commit('setPlatformMutation', result)
  }
  savePlatform()
  const debounceResize = debounce(savePlatform)
  window.onresize = debounceResize
}

Vue.use(Vuex)
export  function createStore() {
  return new Vuex.Store({
    state: {
      APIURI: '',
      platform: 'PC',
      miniCartNum: 0,
      isGuest: true,
      isLogin: false,
      locale: 'en-US',
      accountInfo: {},
      OriginALias: '',
      ...state
    },
    getters: {
      isMobile(state) {
        return state.platform === 'H5'
      },
      isGuest(state) {
        return state.isGuest
      },
      isLogin(state) {
        return state.isLogin
      },
      ...getters
    },
    actions: {
      setIncludeCachePage(page) {
        if (page) {
          this.state.includeCachePage.push(page)
        }
      },
      nuxtServerInit({ commit,...otherStores }, { app,...otherApps }) {
        const headers = app.context.req.headers
        const isGuest = app.$cookies.get('guestId')
        const isLogin = app.$cookies.get('token')
        const local = app.$cookies.get('gt-local')

        const lang = (headers['accept-language']) && (headers['accept-language']).split(',')[0]
        const gtLocal =
        local || langMap[lang] || process.env.lang || app.store.state.locale
        app.$cookies.set('gt-local', gtLocal)
        commit('SET_LANG', gtLocal)
        commit('setGuest', !!isGuest)
        commit('setIsLogin', !!isLogin)
        commit(
          'setPlatformMutation',
          isMobile(headers['user-agent']) ? 'H5' : 'PC'
        )
        const origin = headers.origin || headers.host;
        const isNumberReg = /^\d+$/
        const isIp = origin.split(".")[0]
        commit(
          'setOriginALias',
          process.env.NODE_ENV === 'development'
            ? CONFIG.SITEURL[process.env.PRO_ENV]
            : isNumberReg.test(isIp)?CONFIG.SITEURL[process.env.PRO_ENV]:origin
        )
        nuxtServerInit({ commit,...otherStores }, { app,...otherApps })
      },
      nuxtClientInit({ commit, state,...otherStores },app) {
        const isGuest = localStorage.getItem('guestId')
        const isLogin = localStorage.getItem('token')
        commit('setGuest', !!isGuest)
        commit('setIsLogin', !!isLogin)
        if (state.locale) {
          localStorage.getItem('gt-local')
            ? localStorage.setItem('gt-local', localStorage.getItem('gt-local'))
            : localStorage.setItem('gt-local', state.locale)
        }
        initPlatform(commit)
        nuxtClientInit({ commit, state, ...otherStores }, app)
      },
      ...actions
    },
    mutations: {
      setAPIURI(state, data) {
        state.APIURI = data
      },
      setPlatformMutation(state, data) {
        state.platform = data
      },
      setMiniCartNum(state, data) {
        state.miniCartNum = data
      },
      setGuest(state, data) {
        state.isGuest = data
      },
      setIsLogin(state, data) {
        console.log('setIsLogin', data)
        state.isLogin = data
      },
      SET_LANG(state, locale) {
        state.locale = locale
      },
      SET_USER_INFO(state, data) {
        state.accountInfo = data
      },
      setOriginALias(state, data) {
        state.OriginALias = data
      },
      ...mutations
    }
  })
}
